.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  & > img {
    //width: 40px;
    // width: 140px;
    width: 9.5rem;
    // margin-bottom: 10px;
  }
  & > span {
    color: $pure_white;
    color: #2ba7df;
    font-weight: 500;
    font-size: 20px;
    text-decoration: none;
    margin-left: 10px;
    @include mq(810px, max) {
      display: none;
    }
  }
}

.sidebar-mini {
  .logo {
    & > span {
      display: none;
    }
  }
}

.MuiAppBar-root {
  & > .MuiToolbar-root {
    @include mq(600px, max) {
      gap: 12px;
    }
    & > * {
      @include mq(600px, max) {
        margin: 0 !important;
      }
    }
    & > .header {
      @include mq(600px, max) {
        display: none;
      }
    }
    & > .menu-dropdown > button > span {
      & > .MuiAvatar-root {
        @include mq(600px, max) {
          margin-right: 0;
        }
      }
      & > .icon-holder {
        @include mq(600px, max) {
          display: none;
        }
      }
    }
  }
}

.rtl {
  &.logo {
    flex-direction: row-reverse;
    & > span {
      margin-left: 20px;
      margin-right: 10px;
      white-space: nowrap;
    }
  }
}
