.wallet-widget {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: max-content;
  cursor: pointer;
  @include mq(810px, max) {
    display: none;
  }
  & > img {
    height: 34px;
    filter: invert(60%) sepia(30%) saturate(904%) hue-rotate(170deg) brightness(64%) contrast(100%);
    margin-right: 8px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    & > .title {
      color: $grey;
      font-size: 12px;
      line-height: 16px;
    }
    & > .amount {
      color: $darker_blue;
      font-size: 18px;
      font-weight: 600;
      line-height: 14px;
    }
  }
}
.search-show {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: max-content;
  cursor: pointer;
  @include mq(471px, max) {
    display: none;
  }
  & > img {
    height: 34px;
    filter: invert(60%) sepia(30%) saturate(904%) hue-rotate(170deg) brightness(64%) contrast(100%);
    margin-right: 8px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    & > .title {
      color: $grey;
      font-size: 12px;
      line-height: 16px;
    }
    & > .amount {
      color: $darker_blue;
      font-size: 18px;
      font-weight: 600;
      line-height: 14px;
    }
  }
}

.rtl {
  .wallet-widget {
    flex-direction: row-reverse;
    & > img {
      margin-right: 0;
      margin-left: 8px;
    }
    & > div {
      align-items: flex-end;
      & > .amount {
        direction: rtl;
      }
    }
  }
}
